<template>
    <div>
        <b-button variant="outline-primary" @click="$router.push('/email-templates')" class="mb-1 float-left">Cancel</b-button>
        <b-button variant="primary" @click="save" class="mb-1 float-right">Save Template</b-button>
        <div class="clearfix"></div>
        <b-card>
            <b-form-input v-model="title" placeholder="Give this template a name" :state="titleState"></b-form-input>
        </b-card>
        <b-card title="Template">
            <div v-if="mode === 'DEFAULT'">
                <h5>Main Content</h5>
                <quill-editor
                    :content="content"
                    :options="editorOption"
                    @change="onContentChange"
                    class="mb-2"
                />
                <h5>Footer</h5>
                <quill-editor
                    :content="footer"
                    :options="editorOption"
                    @change="onFooterChange"
                />
                <b-button class="mt-2 float-right" variant="outline-primary" @click="writeCustomHTML">Or write custom HTML →</b-button>
            </div>
            <div v-else>
                <textarea id="custom-template" v-model="customTemplate" />
                <b-button class="mt-2 float-right" variant="outline-primary" @click="useDefaultTemplate">← Use default template instead</b-button>
            </div>
            <h5 class="mt-2">
                Variables Detected: 
                <a href="https://docs.abracadalo.com/notification-api#send" target="_blank"><BIconQuestionCircleFill title="What are Template Variables?" /></a>
            </h5>
            <div v-if="variables.length > 0">
                <b-badge v-for="(variable, index) in variables" :key="index" variant="light" class="mr-1 mt-1">{{ variable }}</b-badge>
            </div>
            <div v-else><em>No variables found.</em></div>
        </b-card>
        <b-card title="Preview">
            <iframe id="preview-email" />
        </b-card>
    </div>
</template>

<script>
import { BCard, BFormInput, BButton, BBadge, BIconQuestionCircleFill } from "bootstrap-vue";
import { mapGetters } from "vuex";
import useAppConfig from '@core/app-config/useAppConfig';
import defaultTemplate from '!raw-loader!@/assets/email-templates/default.html';
import { collection, setDoc, addDoc, getDoc, doc } from "firebase/firestore"
import { getTemplateVariables } from "../libs/template-vars";

export default {
    components: {
        BCard,
        BButton,
        BBadge,
        BFormInput,
        BIconQuestionCircleFill
    },
    data: () => ({
        title: "",
        titleState: null,
        mode: 'DEFAULT',
        skin: null,
        content: `<p>Hello {{ name }},</p><p>how is your <strong>day</strong>?</p>`,
        customTemplate: "",
        footer: "&copy; 2021 MyCompany <a href='#'>Imprint</a>",
        editorOption: {
          // Some Quill options...
        }
    }),
    created() {
        const { skin } = useAppConfig();
        this.skin = skin;

        this.customTemplate = defaultTemplate.replace("###MAIN###", 
            `<p>Hello {{ name }}, </p><p>welcome to our platform.</p><p>Cheers,<br>Tom`)
            .replace('###FOOTER###', 
            `<p>&copy; 2022 Awesome Company Inc.</p>`)

        if (this.title === '') {
            this.fetchTemplate();
        }
    },
	computed: {
		...mapGetters({
			user: "auth/user"
		}),
        variables() {
            return getTemplateVariables(this);
        }
	},
    watch: {
        title() {
            this.titleState = this.title.length > 0;
        },
        customTemplate() {
            this.updatePreview();
        },
        "user.data.uid"() {
            this.fetchTemplate();
        }
    },
    methods: {
        async fetchTemplate() {
            if (this.user.data && this.$route.params.id) {
                const tpl = await getDoc(doc(this.$firestore, 'users', this.user.data.uid, 'email_templates', this.$route.params.id))
                if (!tpl.exists()) {
                    alert('An error occured: This template does not exist!');
                } else {
                    this.title = tpl.data().title;
                    this.mode = tpl.data().mode;
                    this.content = tpl.data().content;
                    this.footer = tpl.data().footer;
                    this.customTemplate = tpl.data().customTemplate;
                }
            }
        },
        useDefaultTemplate() {
            this.mode = 'DEFAULT';
            this.updatePreview();
        },
        writeCustomHTML() {
            this.mode = 'CUSTOM';
            this.updatePreview();
        },
        onContentChange({ html }) {
            this.content = html;
            this.updatePreview();
        },
        onFooterChange({ html}) {
            this.footer = html;
            this.updatePreview();
        },
        updatePreview() {
            const doc = document.getElementById('preview-email').contentWindow.document;
            doc.open();

            let template;
            if (this.mode === 'DEFAULT') {
                template = defaultTemplate
                    .replace('###MAIN###', this.content)
                    .replace('###FOOTER###', this.footer);
            } else {
                template = this.customTemplate;
            }

            doc.write(template);
            doc.close();
        },
		async save() {
            if (this.title.length === 0) {
                this.titleState = false;
                return;
            }

            const ref = collection(this.$firestore, "users", this.user.data.uid, "email_templates");

            const obj = {
                title: this.title,
                mode: this.mode,
                content: this.content,
                footer: this.footer,
                customTemplate: this.customTemplate
            };

            try {
                if (this.$route.params.id) {
                    await setDoc(doc(ref, this.$route.params.id), obj)
                } else {
                    await addDoc(ref, obj);
                }

                this.$swal({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'The template has been saved.',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                });

                this.$router.push('/email-templates');
            } catch (e) {
                console.error(e);

                this.$swal({
                    icon: 'danger',
                    title: 'Oops, something went wrong!',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })
            }
        }
    }
}
</script>

<style lang="scss">
iframe {
    background: white;
    width: 100%;
    height: 300px;
}

#custom-template {
    width: 100%;
    min-height: 200px;
    font-family: "Courier New", monospace;
}

.badge-light {
    color: #555;
}

.bi-question-circle-fill {
    cursor: pointer;
}
</style>